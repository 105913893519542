import React, {Component} from "react";
import "react-moment";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import SelectSearch from "react-select-search";
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
// import {BotonCancelar} from "../../componentes/Plantillas/Botones";
import {Redirect} from "react-router-dom";
import {decimalAdjust, ESTADO_CE} from "../../Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ReporteVentas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ReporteVentasRango: [],
            ReporteTotalVentas: null,
            ReporteTotalPagadoVentas: null,
            ReporteTotalRedondeos: null,

            ReporteVentasClienteRango: [],
            ReporteVentasModalidadPago: [],
            IdCliente: null,
            ModalidadPago: null,
            FechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            FechaFin: moment(new Date()).format('YYYY-MM-DD'),
            TipoReporte: "Sucursal",
            Clientes: [],
            Credito: false,
            Contado: true,
            listaSucursales: [],
            ReporteVentasSucursal: [],
            IdSucursal: -1,
            FiltrarSucursal: false,
            user: {},
            totalAceptados: 0,
            totalAnulados: 0,
            canShowPaymentInfo: false,
            canChangeDateForRecords: false,
        };

        this.getReporteVentasRango = this.getReporteVentasRango.bind(this);
        this.getReporteTotalVentas = this.getReporteTotalVentas.bind(this);
        this.getReporteVentasClienteRango = this.getReporteVentasClienteRango.bind(this);
        this.getReporteVentasModalidadPago = this.getReporteVentasModalidadPago.bind(this);
        this.handleTipoReporte = this.handleTipoReporte.bind(this);
        this.getClientes = this.getClientes.bind(this);
        this.listClientes = this.listClientes.bind(this);
        this.handleSelectCliente = this.handleSelectCliente.bind(this);
        this.handleFechaInicio = this.handleFechaInicio.bind(this);
        this.handleFechaFin = this.handleFechaFin.bind(this);
        this.handleCredito = this.handleCredito.bind(this);
        this.handleContado = this.handleContado.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.getDataExportExcel = this.getDataExportExcel.bind(this);
        this.getSucursales = this.getSucursales.bind(this);
        this.getReporteVentasSucursal = this.getReporteVentasSucursal.bind(this);
        this.handleSelectSucursal = this.handleSelectSucursal.bind(this);
        this.listSucursales = this.listSucursales.bind(this);
        this.ValidarRol = this.ValidarRol.bind(this);
        this.DatosUser = this.DatosUser.bind(this);
        this.onSearchVentas = this.onSearchVentas.bind(this);
        this.extracNombreTipoPagos = this.extracNombreTipoPagos.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);
    }

    componentDidMount() {
        this.DatosUser();
        this.getClientes();
        this.checkPermissions();
    }

    async checkPermissions() {
      try {
        const req = await fetch(`/api/pre-invoice/config`);

        if (!req.ok) {
          throw new Error(await req.text())
        }

        const res = await req.json()

        if ('canShowPaymentInfo' in res) {
          this.setState({ canShowPaymentInfo: !!res.canShowPaymentInfo })
        }

        if ('canChangeDateForRecords' in res) {
          this.setState({ canChangeDateForRecords: !!res.canChangeDateForRecords })
        }
      } catch (e) {
        console.error(e)
      }
    }

    async DatosUser() {
        try {
            const response = await fetch(`/api/configFactElectronica/User`);
            const data = await response.json();
            this.setState({
                IdSucursal: data.objuser.IdSucursal, user: data.objuser
            })
            await this.ValidarRol()
        } catch (error) {
          console.error(error)
        }
    }

    async ValidarRol() {
        try {
            const response = await fetch(`/api/configFactElectronica/ChangeSucursal`);
            const data = await response.json();
            this.setState({
                FiltrarSucursal: true
            })
        } catch (error) {
            this.setState({
                FiltrarSucursal: false
            })
        }
        await this.getSucursales();
    }

    async getSucursales() {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/configFactElectronica/SucursalEmpresa`);
            const data = await response.json();
            this.setState({
                loading: false, listaSucursales: data
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    listSucursales(sucursales) {
        return sucursales.map(sucursal => {
            return {
                name: sucursal.Nombre, value: sucursal.IdSucursal
            };
        });
    }

    async getReporteVentasSucursal(Inicio, Fin, IdSucursal) {
        try {
            const response = await fetch(`/api/ventas/reporte/sucursal/fecha/${Inicio}/${Fin}/${IdSucursal}`);
            const ReporteVentasSucursal = await response.json();
            const datos = ReporteVentasSucursal.respuesta;
            this.setState({
                ReporteVentasSucursal: datos.map(d => {
                    return {...d, _tipoPagos: this.extractTiposPagos(d.tipoPagos)}
                }), isLoading: false,
            });

            await this.getReporteTotalVentas(ReporteVentasSucursal.respuesta);
            return ReporteVentasSucursal;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    extractTiposPagos(tipoPagos) {
        tipoPagos = tipoPagos && tipoPagos.length ? tipoPagos.split(",") : ""
        const tipos = []
        for (let i = 0; i < tipoPagos.length; i += 2) {
            tipos.push({
                tipo: tipoPagos[i], monto: tipoPagos[i + 1]
            })
        }
        return tipos
    }

    extracNombreTipoPagos(tipoPagos) {
        if (!this.state.canShowPaymentInfo) {
          return "";
        }

        return tipoPagos.map((t, index) => {
            let prefix = ""

            if (index) {
                prefix = " - "
            }

            return prefix + t.tipo + ": S./" + decimalAdjust('floor', t.monto, -2)
        }).join("");
    }

    handleSelectSucursal(e) {
        this.setState({IdSucursal: e.value});
    }

    async getReporteVentasRango(Inicio, Fin) {
        let idSucursal = this.getIdSucursal();
        try {
            const response = await fetch(`/api/ventas/reporte/ventas/fecha/${Inicio}/${Fin}?${idSucursal ? `idSucursal=${idSucursal}` : ''}`);
            const ReporteVentasRango = await response.json();
            this.setState({
                ReporteVentasRango: ReporteVentasRango.respuesta, isLoading: false,
            });

            await this.getReporteTotalVentas(ReporteVentasRango.respuesta);

            return ReporteVentasRango;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReporteTotalVentas(Ventas) {
        try {
            let ReporteTotalVentas = 0;
            let ReporteTotalRedondeos = 0;
            let ReporteTotalPagadoVentas = 0;

            Ventas.forEach(element => {
                ReporteTotalVentas += element.Total; //Sumatoria de todos los montos totales (sin redondeo)
                ReporteTotalRedondeos += element.Redondeo; //Sumatoria de todos los redondeos
                ReporteTotalPagadoVentas += element.TotalRedondeo; //Sumatoria de pago real (Incluye redondeo)
            });


            this.setState({
                ReporteTotalVentas: ReporteTotalVentas.toLocaleString("en", {
                    minimumFractionDigits: 4, maximumFractionDigits: 4
                }),
                ReporteTotalRedondeos: ReporteTotalRedondeos.toLocaleString("en", {
                    minimumFractionDigits: 2, maximumFractionDigits: 2
                }),
                ReporteTotalPagadoVentas: ReporteTotalPagadoVentas.toLocaleString("en", {
                    minimumFractionDigits: 4, maximumFractionDigits: 4
                }),
                totalAnulados: Ventas.filter(d => d.EstadoCE === ESTADO_CE.ANULADO).map(d => d.Total).reduce((a, b) => a + b, 0),
                totalAceptados: Ventas.filter(d => d.EstadoCE === ESTADO_CE.ACEPTADO).map(d => d.Total).reduce((a, b) => a + b, 0),
            });
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReporteVentasClienteRango(Inicio, Fin, IdCliente) {
        let idSucursal = this.getIdSucursal();
        try {
            const response = await fetch(`/api/ventas/reporte/cliente/fecha/${Inicio}/${Fin}/${IdCliente || "0"}?${idSucursal ? `idSucursal=${idSucursal}` : ''}`);
            const ReporteVentasClienteRango = await response.json();
            console.log({ReporteVentasClienteRango})
            this.setState({
                ReporteVentasClienteRango: ReporteVentasClienteRango.respuesta.map(d => {
                    return {...d, _tipoPagos: this.extractTiposPagos(d.tipoPagos)}
                }), isLoading: false,
            });

            await this.getReporteTotalVentas(ReporteVentasClienteRango.respuesta);
            return ReporteVentasClienteRango;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReporteVentasModalidadPago(Inicio, Fin, IdModalidadPago) {
        let idSucursal = this.getIdSucursal();
        try {
            const response = await fetch(`/api/ventas/reporte/modalidad/fecha/${Inicio}/${Fin}/${IdModalidadPago}?${idSucursal ? `idSucursal=${idSucursal}` : ''}`);
            const ReporteVentasModalidadPago = await response.json();
            this.setState({
                ReporteVentasModalidadPago: ReporteVentasModalidadPago.respuesta.map(d => {
                    return {...d, _tipoPagos: this.extractTiposPagos(d.tipoPagos)}
                }), isLoading: false,
            });

            await this.getReporteTotalVentas(ReporteVentasModalidadPago.respuesta);
            return ReporteVentasModalidadPago;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getClientes() {
        try {
            const response = await fetch(`/api/clientes/emp/`);
            const Clientes = await response.json();
            this.setState({
                Clientes: Clientes[1], isLoading: false
            });
            return Clientes;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    listClientes(Clientes) {
        return Clientes.map(cliente => {
            return {
                name: cliente.NombreCliente.concat("-", cliente.NroTipoDocumento), value: cliente.IdCliente
            };
        });
    }

    handleSelectCliente(e) {
        this.setState({IdCliente: e.value});
    }

    handleFechaInicio(e) {
        this.setState({FechaInicio: e.target.value});
    }

    handleFechaFin(e) {
        this.setState({FechaFin: e.target.value});
    }

    handleTipoReporte(TipoReporte) {
        this.setState({
            TipoReporte: TipoReporte
        });
    }

    async handleContado() {
        this.setState({
            Contado: true, Credito: false
        });
    }

    async handleCredito(e) {
        this.setState({
            Credito: true, Contado: false
        });
    }

    handleSavePDF(Reporte) {
        let Inicio = moment(this.state.FechaInicio).format("YYYY-MM-DD");
        let Fin = moment(this.state.FechaFin).format("YYYY-MM-DD");

        const mmPageSize = [700, 450];
        const ptPageSize = mmPageSize.map(coord => coord * 2.83465);

        let doc = new jsPDF({
            orientation: "landscape", format: ptPageSize
        });
        var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text(`REPORTE DE VENTAS`

            , 80, 10);

        Inicio === Fin ? doc.text(`PERIODO: ${Inicio}`

            , 40, 20) : doc.text(`PERIODO: ${Inicio} - ${Fin}`

            , 40, 20);
        // console.log(this.state.ReporteVentasClienteRango[0].RazonSocial)
        // //
        this.state.TipoReporte === "Cliente" && this.state.IdCliente != null && this.state.ReporteVentasClienteRango.length > 0 ? doc.text(`CLIENTE: ${this.state.ReporteVentasClienteRango[0].RazonSocial}`

            , 130, 20) : doc.text(``

            , 130, 20)

        this.state.TipoReporte === "Modalidad" ? this.state.Credito === true ? doc.text(`MODALIDAD DE PAGO: CRÉDITO`

            , 130, 20) : doc.text(`MODALIDAD DE PAGO: CONTADO`

            , 130, 20) : doc.text(``

            , 130, 20)
        // console.log(this.state.ReporteVentasSucursal[0])
        // //
        this.state.TipoReporte === "Sucursal" && this.state.IdSucursal != null ? doc.text(`SUCURSAL: ${this.state.ReporteVentasSucursal[0].Sucursal}`

            , 130, 20) : doc.text(``

            , 130, 20)

        doc.autoTable({
            startY: 30, body: Reporte, theme: "plain", styles: {lineWidth: 0.5},

            head: [[{
                content: "FECHA DE EMISIÓN", colSpan: 1, styles: {halign: "center", valign: "middle", cellWidth: 32}
            }, {
                content: "TIPO DE COMPROBANTE", colSpan: 1, styles: {halign: "center", valign: "middle"}
            }, {
                content: "SERIE", colSpan: 1, styles: {halign: "center", valign: "middle"}
            }, {
                content: "NUMERO", colSpan: 1, styles: {halign: "center", valign: "middle"}
            }, {
                content: "TIPO CLIENTE", colSpan: 1, styles: {halign: "center", valign: "middle"}
            }, {
                content: "NRO. CLIENTE", colSpan: 1, styles: {halign: "center", valign: "middle"}
            }, {
                content: "RAZÓN SOCIAL", colSpan: 1, styles: {halign: "center", valign: "middle"}
            },
                {
                    content: "MODALIDAD DE PAGO", colSpan: 1, styles: {halign: "center", valign: "middle"}
                },

                {
                    content: "CANTIDAD DE DÍAS", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "FECHA DE PAGO DEL CRÉDITO", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "ESTADO DEL COMPROBANTE", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "EXPORTACIÓN", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "GRAVADAS", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "EXONERADAS", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "INAFECTAS", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "ISC", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "IGV Y/O IPM", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "ICBPER", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "TOTAL", colSpan: 1, styles: {halign: "center", valign: "middle", cellWidth: 32}
                }, {
                    content: "MONEDA", colSpan: 1, styles: {halign: "center", valign: "middle", cellWidth: 32}
                }, {
                    content: "REDONDEO", colSpan: 1, styles: {halign: "center", valign: "middle", cellWidth: 32}
                }, {
                    content: "TOTAL REDONDEO", colSpan: 1, styles: {halign: "center", valign: "middle", cellWidth: 32}
                }, {
                    content: "TIPO DE CAMBIO", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "FECHA COMPROBANTE MODIFICADO", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "CODIGO", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "SERIE", colSpan: 1, styles: {halign: "center", valign: "middle"}
                }, {
                    content: "NUMERO", colSpan: 1, styles: {halign: "center", valign: "middle"}
                },]],

            columnStyles: {
                text: {
                    cellWidth: "wrap", overflow: "linebreak", halign: "right", valign: "middle"
                }, FechaEmision: {
                    cellWidth: 20, halign: "right", valign: "middle", overflow: "linebreak"
                }, TipoComprobante: {
                    cellWidth: 20, halign: "right"
                }, Serie: {
                    cellWidth: 15, halign: "right"
                }, NumeroComprobante: {
                    cellWidth: 20, halign: "right"
                }, TipoDocumentoCliente: {
                    cellWidth: 20, halign: "right"
                }, NroTipoDocumentoCliente: {
                    cellWidth: 30, halign: "right"
                }, RazonSocial: {
                    cellWidth: 80, halign: "right"
                },

                IdModalidadPago: {
                    cellWidth: 25, halign: "right", valign: "middle", overflow: "linebreak"
                }, CantidadDiasCredito: {
                    cellWidth: 20, halign: "right", valign: "middle", overflow: "linebreak"
                }, FechaPago: {
                    cellWidth: 25, halign: "right", valign: "middle", overflow: "linebreak"
                }, EstadoCE: {
                    cellWidth: 25, halign: "right", valign: "middle", overflow: "linebreak"
                },

                Exportacion: {
                    cellWidth: 20, halign: "right"
                }, Gravadas: {
                    cellWidth: 20, halign: "right"
                }, Exoneradas: {
                    cellWidth: 20, halign: "right"
                }, Inafectas: {
                    cellWidth: 20, halign: "right"
                }, ISC: {
                    cellWidth: 20, halign: "right"
                }, IGV: {
                    cellWidth: 20, halign: "right"
                }, ICBPER: {
                    cellWidth: 20, halign: "right"
                }, Total: {
                    cellWidth: 15, halign: "right"
                }, Moneda: {
                    cellWidth: 15, halign: "right"
                }, Redondeo: {
                    cellWidth: 15, halign: "right"
                }, TotalRedondeo: {
                    cellWidth: 15, halign: "right"
                }, TipoCambio: {
                    cellWidth: 15, halign: "right"
                }, FechaComprobanteModificado: {
                    cellWidth: 30, halign: "right"
                }, CodigoTipoComprobanteModificado: {
                    cellWidth: 20, halign: "right"
                }, SerieComprobanteModificado: {
                    cellWidth: 20, halign: "right"
                }, NumeroComprobanteModificado: {
                    cellWidth: 30, halign: "right"
                }
            }, columns: [{header: "EMISIÓN", dataKey: "FechaEmision"}, {
                header: "TIPO COMPROB.", dataKey: "TipoComprobante"
            }, {header: "SERIE", dataKey: "Serie"}, {
                header: "NÚMERO", dataKey: "NumeroComprobante"
            }, {header: "TIPO CLIENTE", dataKey: "TipoDocumentoCliente"}, {
                header: "NRO. DOCUMENTO", dataKey: "NroTipoDocumentoCliente"
            }, {header: "RAZÓN SOCIAL", dataKey: "RazonSocial"}, {
                header: "MODALIDAD DE PAGO", dataKey: "IdModalidadPago"
            }, {header: "DIAS DE CREDITO", dataKey: "CantidadDiasCredito"}, {
                header: "FECHA DE PAGO DEL CREDITO", dataKey: "FechaPago"
            }, {header: "ESTADO DEL COMPROBANTE", dataKey: "EstadoCE"}, {
                header: "EXPORTACIÓN", dataKey: "Exportacion"
            }, {header: "GRAVADAS", dataKey: "Gravadas"}, {
                header: "EXONERADAS", dataKey: "Exoneradas"
            }, {header: "INAFECTAS", dataKey: "Inafectas"}, {header: "ISC", dataKey: "ISC"}, {
                header: "IGV", dataKey: "IGV"
            }, {header: "ICBPER", dataKey: "ICBPER"}, {header: "TOTAL", dataKey: "Total"}, {
                header: "MONEDA", dataKey: "Abreviatura"
            }, {header: "REDONDEO", dataKey: "Redondeo"}, {
                header: "TOTAL REDONDEO", dataKey: "TotalRedondeo"
            }, {header: "TIPO CAMBIO", dataKey: "TipoCambio"}, {
                header: "FECHA COMPROBANTE MODIFICADO", dataKey: "FechaComprobanteModificado"
            }, {
                header: "CODIGO", dataKey: "CodigoTipoComprobanteModificado"
            }, {
                header: "SERIE", dataKey: "SerieComprobanteModificado"
            }, {
                header: "NUMERO", dataKey: "NumeroComprobanteModificado"
            }]
        });
        // let finalTable = doc.autoTable.previous.finalY;
        console.log(this.state.ReporteTotalVentas, " - ", this.state.ReporteTotalRedondeos)
        doc.setFontSize(12);
        doc.setFontStyle("bold")
        doc.setFontType("bold");
        // doc.text(
        //     `TOTAL DE VENTAS:         $. ${this.state.ReporteTotalVentas}`
        //
        //     , 15, finalTable + 10) //pageWidth -90
        // doc.text(
        //     `TOTAL PAGADO:              $. ${this.state.ReporteTotalPagadoVentas}`
        //
        //     , 15, finalTable + 20) //pageWidth -90
        // doc.text(
        //     `TOTAL DE REDONDEO:   $.   ${this.state.ReporteTotalRedondeos}`
        //
        //     , 15, finalTable + 30) //pageWidth -90
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("ReporteVentas.pdf");
    }

    getDataExportExcel(dataSet) {
        console.log(dataSet);

        return [{
            columns: [{
                title: "Fecha Emision", width: {wpx: 100}
            }, {
                title: "Tipo Comprobante", width: {wpx: 100}
            }, {
                title: "Serie", width: {wpx: 60}
            }, {
                title: "Numero Comprobante", width: {wpx: 100}
            }, {
                title: "Nro Tipo Documento Cliente", width: {wpx: 120}
            }, {
                title: "RazonSocial", width: {wpx: 300}
            }, {
                title: "Estado", width: {wpx: 300}
            }, {
                title: "Modalidad", width: {wpx: 300}
            }, {
                title: "Tipo de pago", width: {wpx: 300}
            }, {
                title: "Días crédito", width: {wpx: 300}
            }, {
                title: "Fecha de Pago", width: {wpx: 300}
            }, {
                title: "Exportacion", width: {wpx: 100}
            }, {
                title: "Gravadas", width: {wpx: 100}
            }, {
                title: "Exoneradas", width: {wpx: 100}
            }, {
                title: "Inafectas", width: {wpx: 100}
            }, {
                title: "ISC", width: {wpx: 100}
            }, {
                title: "IGV", width: {wpx: 100}
            }, {
                title: "ICBPER", width: {wpx: 100}
            }, {
                title: "Total", width: {wpx: 100}
            }, {
                title: "Moneda", width: {wpx: 100}
            }, {
                title: "Redondeo", width: {wpx: 100}
            }, {
                title: "Total Redondeado", width: {wpx: 100}
            },

            ],

            data: dataSet.map(row => {
                return [{
                    value: row.FechaEmision, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.TipoComprobante, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.Serie, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.NumeroComprobante || "", style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.NroTipoDocumento || "", style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.RazonSocial || "", style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.EstadoCE || "", style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: this.extracNombreTipoPagos(row._tipoPagos) || "", style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.IdModalidadPago || "", style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.CantidadDiasCredito || "", style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.FechaPago || "", style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.Exportacion || 0.0, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.Gravadas || 0.0, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.Exoneradas || 0.0, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.Inafectas || 0.0, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.ISC || 0.0, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.IGV || 0.0, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.ICBPER || 0.0, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.Total || 0.0, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.Abreviatura, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.Redondeo, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                }, {
                    value: row.TotalRedondeo, style: {
                        fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                    }
                },

                ];
            })
        }, // {
            //     columns: [
            //         {
            //             title: "TOTAL DE VENTAS",
            //             width: {wpx: 100}
            //         },
            //         {
            //             title: "TOTAL PAGADO",
            //             width: {wpx: 100}
            //         },
            //         {
            //             title: "REDONDEO",
            //             width: {wpx: 60}
            //         }
            //     ],
            //
            //     data: [
            //         [
            //             {
            //                 value: this.state.ReporteTotalVentas,
            //                 style: {
            //                     fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
            //                 }
            //             },
            //             {
            //                 value: this.state.ReporteTotalPagadoVentas,
            //                 style: {
            //                     fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
            //                 }
            //             },
            //             {
            //                 value: this.state.ReporteTotalRedondeos,
            //                 style: {
            //                     fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
            //                 }
            //             }
            //         ],
            //
            //     ]
            // }
        ];
    }

    onClickDetalleVenta(props) {
        this.props.history.push(`/detalleregistroventas/${props.original.IdRegistroVenta}`);
        return <Redirect to={

            `/detalleregistroventas/${props.original.IdRegistroVenta}`

        }/>
    }

    getIdSucursal() {
        if (this.state.FiltrarSucursal) return null;
        return this.state.IdSucursal;
    }

    onSearchVentas() {
        let Inicio = moment(this.state.FechaInicio).format("YYYY-MM-DD");
        let Fin = moment(this.state.FechaFin).format("YYYY-MM-DD");
        let TipoReporte = this.state.TipoReporte;
        this.setState({
            isLoading: true
        })

        if (TipoReporte === "VentaRango") {
            this.getReporteVentasRango(Inicio, Fin);
        }
        if (TipoReporte === "Cliente") {
            let IdCliente = this.state.IdCliente;
            this.getReporteVentasClienteRango(Inicio, Fin, IdCliente);
        }
        if (TipoReporte === "Modalidad") {
            let IdModalidadPago = this.state.Contado === true ? 1 : 2;
            this.getReporteVentasModalidadPago(Inicio, Fin, IdModalidadPago);
        }
        if (TipoReporte === "Sucursal") {
            let IdSucursal = this.state.IdSucursal;
            this.getReporteVentasSucursal(Inicio, Fin, IdSucursal);
        }
    }

    render() {
        let OpcionFiltro = null;

        OpcionFiltro = this.state.TipoReporte;

        let Reporte = null;
        let ReporteTotalPagado = this.state.ReporteTotalPagadoVentas;
        let ReporteTotalRedondeo = this.state.ReporteTotalRedondeos;

        if (OpcionFiltro === "Cliente") {
            Reporte = this.state.ReporteVentasClienteRango;
        }
        if (OpcionFiltro === "VentaRango") {
            Reporte = this.state.ReporteVentasRango;
        }
        if (OpcionFiltro === "Modalidad") {
            Reporte = this.state.ReporteVentasModalidadPago;
        }
        if (OpcionFiltro === "Sucursal") {
            Reporte = this.state.ReporteVentasSucursal;
        }
        if (Reporte) {
            var dataExport = this.getDataExportExcel(Reporte);
        }
        const styleCalendar = {
            border: "0.5px solid #acacac", borderRadius: "25px", outline: "none", flexBasis: "50%", alignSelf: "center"
        };
        return (<>
            <section className="ventas-fpay-section">
                <div className="row w-100 m-auto">
                    <div className="mt-5 titulo-ventas-pay col-12 np" align="center">
                        <h4>Selecciona las opciones del reporte personalizado de Ventas</h4>
                    </div>
                </div>
                <div
                    className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2"
                    // style={{width: "40%", marginLeft: "30%"}}
                >
                    <span className="align-self-center letra-fecha-fpay">Desde: </span>
                    <input
                        type="date"
                        onChange={this.handleFechaInicio}
                        value={this.state.FechaInicio}
                        className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay align-self-center"
                        style={styleCalendar}
                    />
                    <span className="align-self-center letra-fecha-fpay">Hasta: </span>
                    <input
                        type="date"
                        onChange={this.handleFechaFin}
                        value={this.state.FechaFin}
                        className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                        style={styleCalendar}
                    />
                </div>
                <div
                    style={{width: "100%"}}
                    className="d-flex justify-content-center"
                >
                    <div className=" pagos-comprobante">
                        {/*
                            this.state.FiltrarSucursal ? (<button
                            type="button"
                            className={this.state.TipoReporte === "VentaRango" ? "pagos__tipo--comprobante--item--active pestaña-hvr" : "pagos__tipo--comprobante--item pago-comprobante-border-l pestaña-hvr"}
                            value={this.state.TipoReporte}
                            onClick={() => {
                                this.handleTipoReporte("VentaRango");
                            }}
                        >
                            Todas las Ventas
                        </button>) : null
                        */}
                        <button
                            type="button"
                            className={this.state.TipoReporte === "Cliente" ? "pagos__tipo--comprobante--item--active pestaña-hvr" : "pagos__tipo--comprobante--item pestaña-hvr"}
                            value={this.state.TipoReporte}
                            onClick={() => {
                                this.handleTipoReporte("Cliente");
                            }}
                        >
                            Por Cliente
                        </button>
                        <button
                            type="button"
                            className={this.state.TipoReporte === "Modalidad" ? "pagos__tipo--comprobante--item--active pestaña-hvr" : "pagos__tipo--comprobante--item pestaña-hvr"}
                            value={this.state.TipoReporte}
                            onClick={() => {
                                this.handleTipoReporte("Modalidad");
                            }}
                        >
                            Por Modalidad de Pago
                        </button>
                        <button
                            type="button"
                            className={this.state.TipoReporte === "Sucursal" ? "pagos__tipo--comprobante--item--active pestaña-hvr" : "pagos__tipo--comprobante--item pestaña-hvr"}
                            value={this.state.TipoReporte}
                            onClick={() => {
                                this.handleTipoReporte("Sucursal");
                            }}
                        >
                            Por Sucursal
                        </button>
                        <button
                            type="button"
                            className={"pagos__tipo--comprobante--item pestaña-hvr pago-comprobante-border-r"}
                            onClick={() => {
                                this.props.history.push("/registrosventas/reportes/sucursales");
                            }}
                        >
                            General de sucursales
                        </button>
                    </div>
                </div>

                {Reporte ? (<div className="container mt-5">
                    {Reporte.length > 0 && (<>
                        <div className="justify-content-between seccion-sucursal-m no-marg">
                            <div className="row mb-2">
                                <h4 className="align-self-center col-12 col-sm-3">
                                    {" "}
                                    Total Aceptados: {decimalAdjust('floor', this.state.totalAceptados, -4)}
                                </h4>
                                <h4 className="align-self-center col-12 col-sm-3">
                                    {" "}
                                    Total Anulados: {decimalAdjust('floor', this.state.totalAnulados, -4)}
                                </h4>
                                <h4 className="align-self-center col-12 col-sm-3">
                                    {" "}
                                    Total Pagado: {ReporteTotalPagado}
                                </h4>
                                <h4 className="align-self-center col-12 col-sm-3">
                                    {" "}
                                    Total Redondeos: {ReporteTotalRedondeo}
                                </h4>
                            </div>

                            {!this.state.isLoading &&
                                <div className="mt-4 mb-4 seccion-btn-generar seccion-btn-pdf-excel">
                                    <button
                                        className="btn-pdf-mfp mb-3"
                                        onClick={() => this.handleSavePDF(Reporte)}
                                        title="Descarga reporte mensual de Ventas en PDF"
                                    >
                                        Descargar PDF
                                    </button>
                                    <ExcelFile
                                        element={<button
                                            className="btn-excel-mfp ml-2"
                                            title="Descarga reporte de Ventas en EXCEL"
                                        >
                                            Descargar Excel
                                        </button>}
                                        filename="ReporteVentas"
                                    >
                                        <ExcelSheet
                                            dataSet={dataExport}
                                            name="Ventas"
                                        />
                                    </ExcelFile>
                                </div>}
                        </div>
                    </>)}
                    {OpcionFiltro === "Cliente" && (<>
                        <div className="seccion-sucursal-m">
                            <h4 className="sucursal-text-color pad-15-fpay">Selecciona el Cliente</h4>
                            <SelectSearch
                                name="IdCliente"
                                value={this.state.IdCliente}
                                onChange={this.handleSelectCliente}
                                options={this.listClientes(this.state.Clientes)}
                                className="select-search-box"
                                placeholder="Selecciona"
                                required
                            />
                        </div>
                    </>)}
                    {OpcionFiltro === "Sucursal" && (<>
                        <div className="caja-fondo-blanco-m-2">
                            <h4 className="sucursal-text-color pad-15-fpay">Selecciona la Sucursal</h4>
                            {this.state.FiltrarSucursal && this.state.listaSucursales.length ? <SelectSearch
                                    name="IdSucursal"
                                    value={this.state.IdSucursal}
                                    onChange={this.handleSelectSucursal}
                                    options={this.listSucursales(this.state.listaSucursales)}
                                    className="select-search-box"
                                    placeholder="Selecciona"
                                    required
                                /> :
                                <label>{this.state.user && this.state.user.RazonSocial ? this.state.user.RazonSocial : "Sucursal del usuario en sesión"}</label>}
                        </div>

                    </>)}
                    {OpcionFiltro === "Modalidad" && (<>
                        <div className="input-group seccion-sucursal-m centrado">
                            <h4 className="mr-2 no-marg sucursal-text-color pad-15-fpay">Selecciona una
                                modalidad de pago:</h4>
                            <div className="custom-control custom-radio mr-3 radio-texto">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.Contado}
                                    id={"ModalidadContado"}
                                    name="groupOfDefaultRadios1"
                                    checked={this.state.Contado === true}
                                    onClick={this.handleContado}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"ModalidadContado"}
                                >
                                    Contado
                                </label>
                            </div>

                            <div className="custom-control custom-radio mr-3 radio-texto">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    value={this.state.Credito}
                                    id={"ModalidadCredito"}
                                    name="groupOfDefaultRadios1"
                                    checked={this.state.Credito === true}
                                    onClick={this.handleCredito}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={"ModalidadCredito"}
                                >
                                    Crédito
                                </label>
                            </div>
                        </div>
                    </>)}

                    <div className="d-flex justify-content-end seccion-btn-generar">
                        <button onClick={this.onSearchVentas} disabled={this.state.isLoading}
                                className="btn btn-outline-primary btn-verde-mfp">
                            Generar
                        </button>
                    </div>

                    <div className="mt-3">
                        <ReactTable
                            id="ventas"
                            data={Reporte}
                            filterable
                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                            onFilteredChange={(filters) => {
                                let tmpData = [...Reporte]
                                for (let i = 0; i < filters.length; i++) {
                                    if (filters[i].id === "Serie") {
                                        let query = String(filters[i].value).split(',').map(x => x.toLowerCase())
                                        tmpData = tmpData.filter(x => query.includes(String(x[filters[i].id]).toLowerCase()))
                                    } else tmpData = tmpData.filter(x => String(x[filters[i].id]).toLowerCase().includes(String(filters[i].value).toLowerCase()))
                                }
                                this.getReporteTotalVentas(tmpData);
                            }}
                            columns={[{
                                Header: "Info", width: 200, Cell: props => (<div>
                                            <span onClick={() => this.onClickDetalleVenta(props)}>
                                                <button className="btn-verde-mfp" style={{fontSize: "1rem"}}>
                                                    Reimprimir
                                                </button>
                                            </span>
                                        <button
                                            className="ml-1 btn-verde-mfp"
                                            style={{fontSize: "1rem"}}
                                            onClick={e => {
                                                e.preventDefault()
                                                console.log({props})
                                                this.props.history.push(`/pre-invoice/add/${props.original.IdProceso}`)
                                            }}
                                        >
                                            Duplicar
                                        </button>
                                    </div>

                                )
                            }, {
                                columns: [{
                                    Header: "Cliente",
                                    id: "RazonSocial",
                                    width: 250,
                                    accessor: d => d.RazonSocial,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["RazonSocial"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Fecha",
                                    id: "FechaEmision",
                                    accessor: d => d.FechaEmision,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["FechaEmision"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Serie",
                                    id: "Serie",
                                    accessor: d => d.Serie,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["Serie"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Numero Comprobante",
                                    id: "NumeroComprobante",
                                    accessor: d => d.NumeroComprobante,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["NumeroComprobante"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Total", id: "Total", accessor: d => d.Total.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }), filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["Total"]
                                    }), filterAll: true, getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Sucursal",
                                    id: "Sucursal",
                                    accessor: d => d.Sucursal,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["Sucursal"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Tipos de pago", show: this.state.canShowPaymentInfo, id: "tipoPagos", width: 300, accessor: d => {
                                        return d._tipoPagos.map(t => {
                                            return (t.tipo + ": S./" + decimalAdjust('floor', t.monto, -2) + "\n")
                                        });
                                    }, filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["tipoPagos"]
                                    }), filterAll: true, getProps: (state, rowInfo, column) => {

                                        return {
                                            style: {
                                                textAlign: "left",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "EstadoCE",
                                    id: "EstadoCE",
                                    accessor: d => d.EstadoCE,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["EstadoCE"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Modalidad Pago",
                                    id: "IdModalidadPago",
                                    accessor: d => d.IdModalidadPago,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["IdModalidadPago"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Días Crédito",
                                    id: "CantidadDiasCredito",
                                    width: 120,
                                    accessor: d => d.CantidadDiasCredito,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["CantidadDiasCredito"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Fecha de Pago",
                                    id: "FechaPago",
                                    width: 120,
                                    accessor: d => d.FechaPago !== '-' ? d.FechaPago : '-',
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["FechaPago"]}),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Tipo Comprobante",
                                    id: "TipoComprobante",
                                    accessor: d => d.TipoComprobante,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["TipoComprobante"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Moneda",
                                    id: "Abreviatura",
                                    accessor: d => d.Abreviatura,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["Abreviatura"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Redondeo", id: "Redondeo", accessor: d => d.Redondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }), filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["Redondeo"]
                                    }), filterAll: true, getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "TotalRedondeo",
                                    id: "TotalRedondeo",
                                    accessor: d => d.TotalRedondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }) ? d.TotalRedondeo.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }) : 0.0,
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["TotalRedondeo"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Gravadas", id: "Gravadas", accessor: d => d.Gravadas.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }), filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["Gravadas"]
                                    }), filterAll: true, getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Exoneradas",
                                    id: "Exoneradas",
                                    accessor: d => d.Exoneradas.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["Exoneradas"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Inafectas",
                                    id: "Inafectas",
                                    accessor: d => d.Inafectas.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }),
                                    filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["Inafectas"]
                                    }),
                                    filterAll: true,
                                    getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "IGV", id: "IGV", accessor: d => d.IGV.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }), filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["IGV"]
                                    }), filterAll: true, getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "ICBPER", id: "ICBPER", accessor: d => d.ICBPER.toLocaleString("en", {
                                        minimumFractionDigits: 2, maximumFractionDigits: 2
                                    }), filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["ICBPER"]
                                    }), filterAll: true, getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                }, {
                                    Header: "Descuento", id: "DescuentoTotal", accessor: d => {
                                        return d.DescuentoTotal ? d.DescuentoTotal.toLocaleString("en", {
                                            minimumFractionDigits: 2, maximumFractionDigits: 2
                                        }) : "0";
                                    }, filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                                        keys: ["DescuentoTotal"]
                                    }), filterAll: true, getProps: (state, rowInfo, column) => {
                                        return {
                                            style: {
                                                textAlign: "right",
                                                background: rowInfo && rowInfo.row.Estado === "ANULADA" ? "#ffcccc" : null
                                            }
                                        };
                                    }
                                },

                                ]
                            }]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            previousText="Anterior"
                            nextText="Siguiente"
                            noDataText="No se encontraron registros"
                            loadingText="Cargando..."
                            ofText="de"
                            rowsText="filas"
                            pageText="Página"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                    </div>

                </div>) : (<div>Cargando Ventas</div>)}
            </section>
        </>);
    }
}

export default ReporteVentas;
